@import "~bootstrap/dist/css/bootstrap.css";

/* You can add global styles to this file, and also import other style files */
html {
  height: 100%;
}

body {
  background-color: #fff;
  height: 100%;
  margin: 0px;
  /* overflow: hidden; */
  padding: 0px;
}

.wrapper {
  height: 100%;
  min-height: 100%;
}

.header {
  background-color: #00a;
  height: 86px;
  width: 100%;
}

.title {
  color: #fff;
  font-family: Verdana;
  font-weight: bold;
  font-size: 65px;
  margin-left: 16px;
  text-shadow: 3px 4px 5px #000;
  padding: 0px 0px 5px 0px;
}

.clock {
  color: #fff;
  float: right;
  font-family: "Courier New";
  font-weight: bold;
  font-size: 40px;
  letter-spacing: -4px;
  margin-top: 20px;
  margin-right: 16px;
  text-shadow: 3px 4px 5px #000;
}

.content {
  background-color: #0a0;
  display: table;
  width: 100%;
}

.lijst {
  border-spacing: 1px;
  border-collapse: separate;
  color: #fff;
  font-family: Verdana;
  font-weight: bold;
  font-size: 14px;
  height: 100%;
  /* padding-top: 86px; */
  /* position: absolute; */
  top: 0px;
  width: 100%;
}

.lijst th {
  background-color: #00a;
  cursor: default;
  font-size: 12px;
  padding: 4px;
  /*padding-right: 0px;*/
  text-align: center;
}

.lijst td {
  background-color: #00a;
  font-size: 14px;
  padding: 4px;
  text-align: center;
}

.nopadding {
  padding: 0px !important;
}

.boxcolumn {
  min-width: 72px;
  max-width: 72px;
  width: 72px;
}

.boxcolumn2 {
  min-width: 144px;
  max-width: 144px;
  width: 144px;
}

.boxcolumn3 {
  min-width: 96px;
  max-width: 96px;
  width: 96px;
}

.boxdiv {
  height: 1px;
  width: 1px;
}

/* .rotated {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -transform: rotate(90deg);
  -webkit-transform-origin: 0% 100%;
  -moz-transform-origin: 0% 100%;
  -ms-transform-origin: 0% 100%;
  -o-transform-origin: 0% 100%;
  -transform-origin: left middle;
  font-size: 24px;
  z-index: 50;
  opacity: 0.6;
  position: relative;
  top: -37px;
  left: 22px;
  white-space: nowrap;
} */

.fullheight {
  height: 100%;
}

.blue {
  background-color: #00a;
  color: #fff;
}

.subtitle {
  font-family: Verdana;
  font-weight: bold;
  font-size: 14px;
  padding: 8px;
}

.midden {
  text-align: center;
}

.groen {
  background-color: #009800 !important;
}

.geel {
  background-color: #f9d800 !important;
}

.fout {
  cursor: pointer;
  font-weight: bold;
}

.links {
  text-align: left !important;
}

.defaultcursor {
  cursor: default;
}

.dialogParent {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.dialog {
  background-color: #fefefe;
  border: 1px solid black;
  font-family: Verdana;
  font-size: 10pt;
  margin-left: auto;
  margin-right: auto;
  padding: 16px;
  position: relative;
  top: 64px;
  width: 450px;
  color: black;
}

h1 {
  font-family: Verdana;
  font-size: 18pt;
  margin: 0px;
}

.buttonbar {
  margin-top: 8px;
}

button {
  width: 96px;
}

.dialogInput {
  color: black;
  font-weight: bold;
  width: 99%;
}

.dialogRow {
  margin-bottom: 8px;
}

.dialogRow div {
  font-weight: bold;
}

.dialogRow label {
  font-weight: normal;
}

.rood {
  animation: blinkingText 2s infinite;
  background-color: #880000 !important;
}
@keyframes blinkingText {
  0% {
    color: #ff0000;
  }
  20% {
    color: #ff0000;
  }
  50% {
    color: #a00000;
  }
  80% {
    color: #ff0000;
  }
  100% {
    color: #ff0000;
  }
}

.link-white {
  color: white;
  text-decoration: none;
}

.link-white:hover {
  color: yellow;
}

.link-white:visited {
  color: white;
}

.link-white:visited:hover {
  color: yellow;
}

.link-grey {
  color: #707070;
  text-decoration: none;
}

.link-grey:hover {
  color: #2e72af;
}

.link-grey:visited {
  color: #707070;
}

.link-grey:visited:hover {
  color: #2e72af;
}

.cursor-pointer {
  cursor: pointer;
}

.text-grey {
  color: #707070;
}

@media (max-width: 900px) {
  .hidden-mobile {
    display: none;
  }
}
